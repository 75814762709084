import type { Action } from "redux";
import type { ThunkAction } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import type { BannerState } from "./BannerStore";
import { bannerSlice } from "./BannerStore";
import type { SidebarState } from "./SidebarStore";
import { sidebarSlice } from "./SidebarStore";
import type { ModalState } from "./ModalStore";
import { modalSlice } from "./ModalStore";
import type { SessionState } from "./SessionStore";
import { sessionSlice } from "./SessionStore";

export type AppThunk = ThunkAction<void, ApplicationState, null, Action<string>>;
export type RootState = ReturnType<typeof store.getState>;
export type ApplicationDispatch = typeof store.dispatch;
export interface ApplicationState {
    sidebar: SidebarState;
    banners: BannerState;
    session: SessionState;
    modal: ModalState;
}

const rootReducer = {
    [sidebarSlice.name]: sidebarSlice.reducer,
    [bannerSlice.name]: bannerSlice.reducer,
    [sessionSlice.name]: sessionSlice.reducer,
    [modalSlice.name]: modalSlice.reducer,
};

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ["modal/show"],
                ignoredPaths: ["modal.modalComponents"],
            },
        }),
});

type StoreType = typeof store;
let _store: StoreType | undefined = undefined;

export const getStore = () => _store;
export const getDispatch = () => (_store ? _store.dispatch : undefined);
export const setStore = (store: StoreType | undefined) => (_store = store);

setStore(store);

export default store;
export const useAppDispatch: () => ApplicationDispatch = useDispatch;
