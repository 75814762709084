import type { MatchSeries } from "@components/matches/api/MatchSeries";
import type { CustomFieldValues } from "@masterblaster/api";
import { sendCommand } from "@masterblaster/api";
import { DrawerContext, DrawerSaveButton, DrawerSection, MbTextField, SpacedContainer } from "@masterblaster/basics";
import { useGame } from "@src/config";
import camelcase from "camelcase";
import _ from "lodash";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useConnectionFields } from "../header/ConnectionInfoComponent";

export const ConnectionInfoInputs = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    const [info, setInfo] = useState(matchSeries.connectionInfo?.fields);
    const { setKeyChanged } = useContext(DrawerContext);
    const { settings, connectionInfoFields } = useGame(matchSeries.gameId);
    const changeKey = "ConnectionInfoFields";
    const { t } = useTranslation("common");

    const { connectionFields } = useConnectionFields(info, connectionInfoFields);

    const onSave = async () => {
        const updated = connectionFields.reduce((p, c) => ({ ...p, [c.field]: c.value }), {});
        await sendCommand("UpdateMatchConnectionInfo", {
            matchSeriesId: matchSeries.id,
            fields: updated,
        });
    };

    if (!settings.useManualConnectionInfo) {
        return null;
    }

    return (
        <SpacedContainer>
            <DrawerSection>{t("shared.connection_info")}</DrawerSection>

            {connectionFields.map((x) => {
                return (
                    <MbTextField
                        key={x.field}
                        label={x.displayName}
                        fullWidth
                        value={x.value ?? ""}
                        onChange={(e) => {
                            setInfo((prev) => ({
                                ...prev,
                                [camelcase(x.field)]: e.currentTarget.value,
                                [x.field]: e.currentTarget.value,
                            }));

                            setKeyChanged(changeKey);
                        }}
                        style={{ minWidth: 300 }}
                    />
                );
            })}

            <DrawerSaveButton changeKey={changeKey} onClick={onSave} />
        </SpacedContainer>
    );
};
