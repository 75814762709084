import type { Theme } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import { createContext, type FC, type PropsWithChildren } from "react";
import { hasUserDefinedColors } from "./designFeatures";
import { darkTheme, userDefinedTheme } from "./theme";

export const ThemeSwitcherContext = createContext<{
    theme: Theme;
    isDark: boolean;
    toggleTheme?(dark: boolean): void;
}>(undefined!);

export const ThemeSwitcher: FC<PropsWithChildren<unknown>> = (props) => {
    const getTheme = () => {
        if (hasUserDefinedColors) {
            return userDefinedTheme;
        }

        return darkTheme;
    };

    const theme = getTheme();

    return (
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        <ThemeSwitcherContext.Provider value={{ theme: theme, isDark: true, toggleTheme: () => {} }}>
            <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
        </ThemeSwitcherContext.Provider>
    );
};
