import { CsMatchDrawer } from "@components/competitions/tournament/games/csgo/matchDrawer/CsMatchDrawer";
import { PubgMatchDrawer } from "@components/competitions/tournament/games/pubg/PubgMatchDrawer";
import type { CompetitionTeamDto } from "@components/team/TeamService";
import type { TournamentDto } from "@masterblaster/api";
import { CompetitionFormat, GameMode, GameType, sendCommand } from "@masterblaster/api";
import {
    ButtonContainer,
    DrawerContent,
    DrawerContext,
    DrawerContextProvider,
    DrawerHeader,
    DrawerSection,
    ErrorText,
    GfLabelWrapper,
    LinkBehaviour,
    PropertyList,
    Switch,
} from "@masterblaster/basics";
import { AccessLevels, isAdministrator } from "@mb/auth";
import { Box, Drawer, Link } from "@mui/material";
import { useAuthorization } from "@services/AuthSerivce";
import { useCompetition, useCompetitionTeams } from "@services/Tournament";
import { useGame } from "@src/config";
import { hasCompetitionStarted } from "@src/utils/tournamentUtilities";
import { showConfirmDialog } from "@utils/DialogHelpers";
import { generateLink } from "@utils/generateLink";
import type { FC } from "react";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MatchViewMode, getHead2HeadTeams, useHead2HeadTeams } from "../api/MatchApi";
import type { MatchSeries } from "../api/MatchSeries";
import { MatchStatus } from "../api/MatchSeries";
import { ConnectionInfoInputs } from "./ConnectionInfoInputs";
import { MatchBoXInput } from "./MatchBoXInput";
import { MatchControls, useMatchControlsState } from "./MatchControls";
import { MatchSeriesTeamsForSuperAdmins } from "./MatchSeriesTeamsForSuperAdmins";
import { MatchStartingAtInput } from "./MatchStartingAtInput";
import { MatchStatusSelector } from "./MatchStatusSelector";
import { MatchSeriesMatchesInput } from "./MatchesInput";
import { ScoreInputs } from "./ScoreInputs";
import { TeamsInput } from "./TeamsInput";
import { TwitchSection } from "./TwitchSection";
import { VetoSettings } from "./VetoSettings";

export const useMatchDrawer = (
    matchSeries: MatchSeries,
    viewMode?: MatchViewMode,
    competition?: TournamentDto,
    competitionTeams?: CompetitionTeamDto[]
) => {
    const [open, setOpen] = useState(false);

    const { competition: loadedCompetition } = useCompetition(
        !competition && open ? matchSeries.competitionId : undefined
    );

    const { competitionTeams: loadedCompetitionTeams } = useCompetitionTeams(
        !competitionTeams && open ? matchSeries.competitionId : undefined
    );

    const comp = competition ?? loadedCompetition;
    const teams = useMemo(
        () => competitionTeams ?? loadedCompetitionTeams ?? [],
        [competitionTeams, loadedCompetitionTeams]
    );
    const drawer = useMemo(() => {
        if (!open) {
            return null;
        }

        if (!comp) {
            return null;
            // return (
            //     <Drawer anchor="right" open={open}>
            //         <DrawerHeader onClose={() => setOpen(false)} />
            //         <DrawerContent>
            //             <LinearProgress />
            //         </DrawerContent>
            //     </Drawer>
            // );
        }

        return (
            <DrawerContextProvider showConfirmDialog={showConfirmDialog}>
                <MatchDrawer
                    matchSeries={matchSeries}
                    tournament={comp}
                    competitionTeams={teams}
                    viewMode={viewMode ?? MatchViewMode.Normal}
                    onClose={() => setOpen(false)}
                />
            </DrawerContextProvider>
        );
    }, [comp, matchSeries, teams, viewMode, open]);

    return {
        openMatchDrawer: () => setOpen(true),
        closeMatchDrawer: () => setOpen(false),
        matchDrawer: drawer,
    };
};

const MatchDrawer: FC<{
    matchSeries: MatchSeries;
    tournament: TournamentDto;
    competitionTeams: CompetitionTeamDto[];
    viewMode: MatchViewMode;
    onClose?: () => void;
}> = (props) => {
    const { matchSeries, tournament, competitionTeams, viewMode } = props;
    const { closeDrawer } = useContext(DrawerContext);
    const isAdmin = isAdministrator();
    const isGameAdmin = useAuthorization("Tournament", matchSeries.competitionId, AccessLevels.GameAdmin);
    const { t } = useTranslation(["common", "translation", "validation"]);
    const states = useMatchControlsState(matchSeries);
    const { settings } = useGame(tournament.gameId);

    if (!isGameAdmin) {
        return null;
    }

    const close = async () => {
        const confirmed = await closeDrawer(true);
        if (confirmed) {
            props.onClose?.();
        }
    };

    const disqualifiedReason = matchSeries.teams
        .filter((x) => x.disqualified)
        .map((x) => ({ teamId: x.team?.id, reason: x.disqualifiedReason }))
        .join(" - ");

    const forfeitReason = matchSeries.teams
        .filter((x) => x.disqualified)
        .map((x) => ({ teamId: x.team?.id, reason: x.disqualifiedReason }))
        .join(" - ");

    const [teamA, teamB] = getHead2HeadTeams(matchSeries.teams);
    const teams =
        tournament.format === CompetitionFormat.BattleRoyale
            ? matchSeries.teams.map((x) => x.team?.name)
            : `${teamA?.team?.name} vs ${teamB?.team?.name}`;

    const castOverlayUrl = `https://tv.skagerrak.tech/match/${matchSeries.id}`;

    const data = [
        {
            label: t("common:shared.id"),
            value: (
                <Link
                    component={LinkBehaviour}
                    href={generateLink("MATCH_ROUTES.LOBBY", { id: matchSeries.id })}
                    target="_blank"
                >
                    {matchSeries.id}
                </Link>
            ),
        },
        { label: t("common:shared.name"), value: matchSeries.name },
        {
            label: t("translation:components.tournament.match.drawer.match_drawer.cast_overlay"),
            value: (
                <Link href={castOverlayUrl} target="_blank">
                    <span>{t("common:shared.go_to")}</span>
                </Link>
            ),
            visible: isAdmin,
        },
        { label: t("common:shared.teams"), value: teams },
        { label: t("common:shared.status"), value: MatchStatus[matchSeries.status] },
        { label: t("common:shared.paused"), value: matchSeries.pauseReason, visible: !!matchSeries.pauseReason },
        { label: t("common:shared.failed"), value: matchSeries.failedReason, visible: !!matchSeries.failedReason },
        { label: t("common:shared.walkover"), value: forfeitReason, visible: matchSeries.forfeitedOrDisqualified },
        {
            label: t("translation:components.tournament.match.drawer.match_drawer.disqualified"),
            value: disqualifiedReason,
            visible: matchSeries.forfeitedOrDisqualified,
        },
    ];

    const isSeeding = viewMode === MatchViewMode.AdminSeeding;

    const isTournamentStarted = tournament && hasCompetitionStarted(tournament?.status);

    return (
        <Drawer anchor="right" open={true} onClick={(e) => e.stopPropagation()} onClose={close}>
            <DrawerHeader onClose={() => close()} />
            <DrawerContent>
                <DrawerSection>{t("common:shared.match_details")}</DrawerSection>
                <PropertyList properties={data} />

                {!isSeeding && <VetoSettings matchSeries={matchSeries} vetoConfiguration={tournament?.veto} />}

                <DrawerSection>{t("common:shared.starting")}</DrawerSection>
                <MatchStartingAtInput matchSeries={matchSeries} tournament={tournament} />

                {settings.mode === GameMode.Head2Head && (
                    <>
                        <MatchBoXInput matchSeries={matchSeries} />
                        <TeamsInput
                            tournament={tournament}
                            matchSeries={matchSeries}
                            competitionTeams={competitionTeams}
                        />
                    </>
                )}

                <ConnectionInfoInputs matchSeries={matchSeries} />

                {!isSeeding && tournament.format !== CompetitionFormat.BattleRoyale && (
                    <ScoreInputs matchSeries={matchSeries} />
                )}
                {!isSeeding && <GameSpecificDrawer matchSeries={matchSeries} />}

                {!isSeeding && (
                    <Box marginTop="1rem">
                        <DrawerSection>
                            {t(`translation:components.tournament.match.drawer.match_controls.match_controls`)}
                        </DrawerSection>

                        {states.helperText && <ErrorText size="xxs">{states.helperText}</ErrorText>}
                        <ButtonContainer>
                            <MatchControls matchSeries={matchSeries} />
                        </ButtonContainer>
                    </Box>
                )}

                {isGameAdmin && tournament.format === CompetitionFormat.RoundRobin && (
                    <>
                        <DrawerSection>{t("common:shared.scheduling")}</DrawerSection>
                        {!isTournamentStarted && (
                            <ErrorText size="xxs">
                                {t(
                                    "validation:components.tournament.cannot_change_rescheduling_option_before_tournament_start"
                                )}
                            </ErrorText>
                        )}
                        <RescheduleSection matchSeries={matchSeries} disabled={!isTournamentStarted} />
                    </>
                )}

                <TwitchSection matchSeries={matchSeries} />

                {isAdmin && (
                    <>
                        <DrawerSection>
                            {t("translation:components.tournament.match.drawer.match_drawer.platform_admin", {
                                name: t("common:shared.status"),
                            })}
                        </DrawerSection>
                        <MatchStatusSelector matchSeries={matchSeries} />

                        <DrawerSection>
                            {t("translation:components.tournament.match.drawer.match_drawer.platform_admin", {
                                name: t("common:shared.matches"),
                            })}
                        </DrawerSection>
                        <MatchSeriesMatchesInput matchSeries={matchSeries} />

                        <DrawerSection>
                            {t("translation:components.tournament.match.drawer.match_drawer.platform_admin", {
                                name: t("common:shared.teams"),
                            })}
                        </DrawerSection>
                        <MatchSeriesTeamsForSuperAdmins matchSeries={matchSeries} />
                    </>
                )}
            </DrawerContent>
        </Drawer>
    );
};

const RescheduleSection = ({ matchSeries, disabled }: { matchSeries: MatchSeries; disabled?: boolean }) => {
    const { t } = useTranslation(["common"]);

    const onMatchRescheduleToggle = (enabled: boolean) => {
        if (enabled) {
            sendCommand("EnableMatchRescheduleCommand", { matchSeriesId: matchSeries.id });
        } else {
            sendCommand("DisableMatchRescheduleCommand", { matchSeriesId: matchSeries.id });
        }
    };

    return (
        <GfLabelWrapper label={t("common:shared.reschedule_match")}>
            <Switch
                label={t("common:shared.allow_rescheduling")}
                disabled={disabled}
                checked={matchSeries.isMatchReschedulingEnabled}
                onChanged={(value) => {
                    onMatchRescheduleToggle(value);
                }}
            />
        </GfLabelWrapper>
    );
};

const GameSpecificDrawer = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    if (matchSeries.game === GameType.CSGO) {
        return <CsMatchDrawer matchSeries={matchSeries} />;
    }

    if (matchSeries.game === GameType.PUBG) {
        return <PubgMatchDrawer matchSeries={matchSeries} />;
    }

    return null;
};
