import { NotificationsProvider } from "@components/notifications";
import { getBrowserLocale } from "@masterblaster/api";
import { GfLoaderFullPage } from "@masterblaster/basics";
import { CustomGlobalStyles, ThemeSwitcher } from "@masterblaster/theme";
import { CssBaseline } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { closeSidebar } from "@store/core";
import { Mixpanel } from "@utils/mixpanel";
import type { History, LocationState } from "history";
import { createBrowserHistory } from "history";
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { Router } from "react-router";
import type { To } from "react-router-dom-v5-compat";
import { CompatRouter } from "react-router-dom-v5-compat";
import { applicationVersion, environment, isProduction } from "./config/AppConfigKeeper";
import { AppConfigProvider } from "./config/AppConfigProvider";
import { initializeSentry } from "./config/ConfigureExternalServices";
import GlobalErrorBoundary from "./errors/GlobalErrorBoundary";
import "./locales/i18n";
import { configureHttpModule } from "./services/Http";
import store from "./store";

const Layout = lazy(() => import("./components/Layout"));

configureHttpModule();
initializeSentry();

console.log(`App: version=${applicationVersion}, environment=${environment}`);

const createWrappedHistory = (history: History) => {
    const push = (location: To, state?: LocationState) => {
        history.push(location, state);
        Mixpanel.trackPage();
    };

    return {
        ...history,
        push,
    } as History;
};

const baseUrl = "/";
const history = createWrappedHistory(createBrowserHistory({ basename: baseUrl }));

history.listen((location) => {
    const isSidebarOpen = store.getState().sidebar.isSidebarOpen;
    if (isSidebarOpen) store.dispatch(closeSidebar());
});

const locale = getBrowserLocale();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <ThemeSwitcher>
        <CssBaseline />
        <CustomGlobalStyles />
        <Provider store={store}>
            <Router history={history}>
                <AppConfigProvider>
                    <NotificationsProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                            <CompatRouter>
                                <GlobalErrorBoundary>
                                    {!isProduction() && (
                                        <Helmet defer={false}>
                                            <meta name="robots" content="noindex,nofollow" />
                                        </Helmet>
                                    )}
                                    <Suspense fallback={<GfLoaderFullPage />}>
                                        {/* 
                                    Triggers double render and fetching data multiple times, obsurcing the network tab. 
                                    Disabling StrictMode for now. Will have to come back to this when fixing the others stuff.

                                    https://github.com/facebook/react/issues/24502
                                 */}
                                        {/* <StrictMode> */}
                                        <Layout />
                                        {/* </StrictMode> */}
                                    </Suspense>
                                </GlobalErrorBoundary>
                            </CompatRouter>
                        </LocalizationProvider>
                    </NotificationsProvider>
                </AppConfigProvider>
            </Router>
        </Provider>
    </ThemeSwitcher>
);
